import React from "react"
import { Grid, Paper, Typography } from "@mui/material"
import User from "../../api/user"
import { Page } from "../../component"
import { useSearchParams } from "react-router-dom"

/**
 * VerifyUser Component
 */
function VerifyUser() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")
  const [message, setMessage] = React.useState("")

  React.useEffect(() => {
    const trigger = async () => {
      if (!token) {
        setMessage("Invalid Token")
      }

      const { success } = await User.verify(token)

      if (success) {
        setMessage("Thank you for validate, redirect in 2 seconds")
      } else {
        setMessage("Error in validate user")
      }

      redirectToAuth()
    }

    trigger()
  }, [token])

  return (
    <Page pageId={VerifyUser} noPadding={true}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontWeight: 700 }}>
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Page>
  )
}

/**
 * redirect back to auth after 3 second
 */
function redirectToAuth() {
  setTimeout(() => {
    location.assign("/auth")
  }, 2000)
}

export default VerifyUser
