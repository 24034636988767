import * as React from "react"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"

import { colors } from "../../theme/colors"
import { Box, Stack } from "@mui/material"
import { File, AudioPlayer } from "../../component"
import { createDownloadUrl } from "../../util"

const attachmentContainerStyle = {
  " & h5": {
    color: colors.teal,
  },
  " & h6": {
    color: colors.grey,
  },
}

type WMCFile = {
  _id?: string
  downloadUrl?: string
  fileName?: string
  fileType?: string
  instrumentType?: string
}

interface InstrumentSheetProps {
  instrument: string
  file: WMCFile
}

type AttachmentStateProps = {
  musicSheet?: WMCFile
  instrumentSheet?: InstrumentSheetProps[]
  files?: WMCFile[]
  fileToken?: string
  handleDeleteMusic: (_id: string) => void
}

/**
 * Project Detail Attachment Section
 */
export default function Attachment(props: AttachmentStateProps) {
  const { musicSheet = {}, instrumentSheet = [], files = [], fileToken, handleDeleteMusic } = props
  let mainSheet: InstrumentSheetProps[] = []
  if (Object.keys(musicSheet).length > 0) {
    mainSheet.push({
      instrument: "main",
      file: musicSheet,
    })
  }

  const sheetFiles = [...mainSheet, ...instrumentSheet].map(
    (sheet: InstrumentSheetProps, idx: React.Key) => {
      const { instrument, file } = sheet
      const { downloadUrl, fileName } = file
      const fullDownloadUrl = createDownloadUrl(downloadUrl, fileToken)
      return (
        <File key={idx} instrument={instrument} fileName={fileName} downloadUrl={fullDownloadUrl} />
      )
    }
  )

  const musicFileList = files.map((file: WMCFile, idx: React.Key) => {
    const { downloadUrl, fileName, instrumentType = "", _id = "" } = file
    const fullDownloadUrl = createDownloadUrl(downloadUrl, fileToken)
    return (
      <Grid key={idx} item xs={12} sm={6}>
        <AudioPlayer
          fileId={_id}
          handleDelete={handleDeleteMusic}
          fileName={fileName}
          instrument={instrumentType}
          src={fullDownloadUrl}
          colors={colors}
        />
      </Grid>
    )
  })

  return (
    <Grid container sx={attachmentContainerStyle}>
      <Grid item xs={12}>
        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography variant="h5">Music Sheet</Typography>
          <Stack
            direction={{ xs: "column" }}
            spacing={{ xs: 1 }}
            alignItems="flex-start"
            paddingY={1}
          >
            {sheetFiles}
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography variant="h5">Music Files</Typography>
          <Grid container spacing={1}>
            {musicFileList}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
